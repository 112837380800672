import Button from "components/Button";
import InputCheckbox from "components/InputCheckbox";
import InputText from "components/InputText";
import InputTextArea from "components/InputTextArea";
import Link from "components/Link";
import SEO from "components/SEO";
import Spinner from "components/Spinner";
import Wrapper from "components/Wrapper";
import { useTranslation } from "hooks/useTranslation";
import React, { ChangeEvent, useState } from "react";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import styled from "styled-components";

interface Props {
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: any[];
    pagePath: string;
  };
  intl: InjectedIntl;
}

interface contactInfo {
  name: string;
  phone: string;
  email: string;
  howDidYouKnowUs: string;
  yourStory: string;
}

interface contactErrors {
  name: boolean;
  phone: boolean;
  email: boolean;
  howDidYouKnowUs: boolean;
  yourStory: boolean;
}

function Page({ pageContext, intl }: Props) {
  const { locale, defaultLocale, pagePath } = pageContext;
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [honeyPot, setHoneyPot] = useState("");
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
  const { formatMessage } = useTranslation();
  const initialStatus = {
    name: "",
    phone: "",
    email: "",
    howDidYouKnowUs: "DEFAULT",
    yourStory: "",
  };
  const [contactInfo, setContactInfo] = useState<contactInfo>(initialStatus);
  const [contactErrors, setContactErrors] = useState<contactErrors>({
    name: false,
    phone: false,
    email: false,
    howDidYouKnowUs: false,
    yourStory: false,
  });

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (honeyPot) {
      return;
    }

    let errors = {};
    Object.keys(contactInfo).forEach((key: string) => {
      if (
        !contactInfo[key] ||
        (key === "howDidYouKnowUs" && contactInfo[key] === "DEFAULT")
      ) {
        errors[key] = true;
      }
    });
    setContactErrors({ ...contactErrors, ...errors });
    if (!isPrivacyChecked) {
      setPrivacyError(true);
      return;
    }
    if (
      Object.values(errors).reduce(
        (acc, currentValue) => currentValue || acc,
        false
      )
    ) {
      return;
    }
    setSubmissionStatus("LOADING");
    try {
      await fetch("/.netlify/functions/contact-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...contactInfo,
          honeyPot,
          isPrivacyChecked,
        }),
      });
      setSubmissionStatus("SUCCESS");
      (window as any).dataLayer.push({
        event: "gaEvent",
        eventCategory: "Form",
        eventAction: "Contatti",
      });

      setContactInfo(initialStatus);
      if (isNewsletterChecked) {
        try {
          await fetch("/.netlify/functions/newsletter-subscription", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: contactInfo.email,
              listId: "ac7d57c51f",
              shouldConfirm: false,
            }),
          });
          (window as any).dataLayer.push({
            event: "gaEvent",
            eventCategory: "Gestione Account",
            eventAction: "Iscrizione Newsletter",
          });
        } catch (err) {}
      }
    } catch (err) {
      setSubmissionStatus("ERROR");
    }
  }
  return (
    <Wrapper>
      <PageContainer>
        <SEO
          title={formatMessage({ id: "contacts.pageTitle" })}
          description={formatMessage({ id: "contacts.pageDescription" })}
          link={[
            { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
          ]}
        />
        <Header>
          <Title>
            <FormattedMessage id="contacts.pageTitle" />
          </Title>
        </Header>
        <ContactsContainer>
          <Contact>
            <ContactTitle>
              <FormattedMessage id="contacts.phoneTitle" />
            </ContactTitle>
            <ContactContent>
              <FormattedMessage id="contacts.phone" />
            </ContactContent>
          </Contact>
          <Contact>
            <ContactTitle>
              <FormattedMessage id="contacts.addressTitle" />
            </ContactTitle>
            <ContactContent>
              Via dei Mille, 99
              <br />
              19100La Spezia (SP)
              <br />
              <br />
              Via Leccio, 21
              <br />
              55016 Porcari (LU) Italy
            </ContactContent>
          </Contact>
          <Contact>
            <ContactTitle>Email</ContactTitle>
            <ContactContent>shop@ruedesmille.com</ContactContent>
          </Contact>
        </ContactsContainer>
        <FormContainer>
          <ContactsText>
            <div>
              <FormattedMessage id="contacts.message1" />
            </div>
            <Subtitle>
              <FormattedMessage id="contacts.formTitle" />
            </Subtitle>
            <div>
              <FormattedMessage id="contacts.message2" />
            </div>
          </ContactsText>
          <Form onSubmit={handleSubmit}>
            <InputText
              type="text"
              placeholder={intl.formatMessage({
                id: "label.name",
                defaultMessage: "Name",
              })}
              value={contactInfo.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setContactErrors({ ...contactErrors, name: false });
                setContactInfo({ ...contactInfo, name: e.target.value });
              }}
              hasError={!!contactErrors["name"]}
            />
            <InputText
              type="text"
              placeholder={intl.formatMessage({
                id: "label.phone",
                defaultMessage: "Phone",
              })}
              value={contactInfo.phone}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setContactErrors({ ...contactErrors, phone: false });
                setContactInfo({ ...contactInfo, phone: e.target.value });
              }}
              hasError={!!contactErrors["phone"]}
            />
            <InputText
              type="email"
              siqatrib="email"
              placeholder="Email"
              value={contactInfo.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setContactErrors({ ...contactErrors, email: false });
                setContactInfo({ ...contactInfo, email: e.target.value });
              }}
              hasError={!!contactErrors["email"]}
            />
            <InputText
              as="select"
              value={contactInfo.howDidYouKnowUs}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setContactErrors({
                  ...contactErrors,
                  howDidYouKnowUs: false,
                });
                setContactInfo({
                  ...contactInfo,
                  howDidYouKnowUs: e.target.value,
                });
              }}
              hasError={!!contactErrors["howDidYouKnowUs"]}
            >
              <option value="DEFAULT" disabled>
                {intl.formatMessage({
                  id: "label.howDidYouKnowUs",
                  defaultMessage: "How did you know us",
                })}
              </option>
              <option value="internet">
                {intl.formatMessage({
                  id: "contacts.howDidYouKnowUsOption1",
                  defaultMessage: "Internet",
                })}
              </option>
              <option value="socialMedia">
                {intl.formatMessage({
                  id: "contacts.howDidYouKnowUsOption2",
                  defaultMessage: "Social Media",
                })}
              </option>
              <option value="magazine">
                {intl.formatMessage({
                  id: "contacts.howDidYouKnowUsOption3",
                  defaultMessage: "Magazine",
                })}
              </option>
              <option value="friends">
                {intl.formatMessage({
                  id: "contacts.howDidYouKnowUsOption4",
                  defaultMessage: "Friends",
                })}
              </option>
              <option value="seller">
                {intl.formatMessage({
                  id: "contacts.howDidYouKnowUsOption5",
                  defaultMessage: "Seller",
                })}
              </option>
            </InputText>
            <InputTextArea
              as="textarea"
              placeholder={intl.formatMessage({
                id: "label.yourStory",
                defaultMessage: "Your story",
              })}
              value={contactInfo.yourStory}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setContactErrors({ ...contactErrors, yourStory: false });
                setContactInfo({ ...contactInfo, yourStory: e.target.value });
              }}
              hasError={!!contactErrors["yourStory"]}
            />
            <InputTextHoney
              tabindex="-1"
              autocomplete="nope"
              placeholder="La tua storia"
              value={honeyPot}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setHoneyPot(e.target.value);
              }}
            />
            <InputCheckbox
              id="contacts-privacy-checkbox"
              hasError={privacyError}
              isChecked={isPrivacyChecked}
              onToggle={() => {
                setIsPrivacyChecked((old) => !old);
                setPrivacyError(false);
              }}
            >
              <FormattedMessage
                id="contacts.privacy"
                values={{
                  privacyPolicy: (
                    <Link
                      to={`${
                        locale === defaultLocale
                          ? "/privacy"
                          : `/${locale}/privacy`
                      }`}
                    >
                      privacy
                    </Link>
                  ),
                }}
              />
            </InputCheckbox>
            <InputCheckbox
              id="contacts-newsletter-checkbox"
              hasError={false}
              isChecked={isNewsletterChecked}
              onToggle={() => setIsNewsletterChecked((old) => !old)}
            >
              <FormattedMessage
                id="contacts.subscribeToNewsletter"
                defaultMessage="Subscribe to the newsletter"
              />
            </InputCheckbox>
            {submissionStatus === "ERROR" && <div>Something went wrong</div>}
            {submissionStatus === "SUCCESS" && (
              <SuccessMessage>
                <FormattedMessage
                  id="contacts.success"
                  defaultMessage="Message sent"
                />
              </SuccessMessage>
            )}
            <ButtonContainer>
              <SubmitButton
                role="submit"
                disabled={submissionStatus === "LOADING"}
              >
                {submissionStatus === "LOADING" ? (
                  <Spinner />
                ) : (
                  <FormattedMessage id="contacts.submit" />
                )}
              </SubmitButton>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </PageContainer>
    </Wrapper>
  );
}

const PageWithIntl = injectIntl(Page);

const ContactTemplate = ({ pageContext }: Props) => {
  return <PageWithIntl pageContext={pageContext} />;
};

const SuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  width: 100%;
`;

const Subtitle = styled.h2`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  width: 100%;
`;

const InputTextHoney = styled(InputText)`
  display: none !important;
`;

const ContactsContainer = styled.div`
  display: flex;
  padding-bottom: 60px;
  width: calc(100% - 60px);
  @media (max-width: 750px) {
    width: calc(100% - 20px);
    flex-direction: column;
  }
`;

const Contact = styled.div`
  width: 33.33%;
  flex: 0 0 33.33%;
  background-color: ${({ theme }) => theme.colors.background2};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  padding: 40px 30px;
  font-size: 14px;
  letter-spacing: 0.06em;
  box-sizing: border-box;
  @media (max-width: 750px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const ContactTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 20px;
`;

const ContactContent = styled.div``;
const PageContainer = styled.div``;
const FormContainer = styled.div``;

const ContactsText = styled.div`
  font-size: 14px;
  letter-spacing: 0.06em;
  text-align: center;
  padding-bottom: 50px;
  width: 600px;
  margin: 0 auto;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  margin-top: 40px;
  justify-content: space-between;
`;

const Form = styled.form`
  width: 500px;
  margin: 0 auto;
  text-align: left;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const SubmitButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
`;

export default ContactTemplate;
